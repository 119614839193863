import { json } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import { confetti } from "@tsparticles/confetti";
import { useEffect, useRef, useState } from "react";
import ButtonRow from "~/components/ButtonRow";
import DialCollection from "~/components/DialCollection";
import HighlightedOutput from "~/components/HighlightedOutput";
import YourLanguageEQLogo from "~/components/YourLanguageEQLogo";
import useInputAnalysis from "~/hooks/useInputTextAnalysis";
import CenterColumn from "~/layouts/CenterColumn";
import { getAllKeywordLists } from "~/models/keywords.server";
import getIsHighLevel from "~/utils/getIsHighLevel";
import getLevelFromPercent from "~/utils/getLevelFromPercent";

export const loader = async () => {
  const debugMode = process.env.DEBUG === "True";
  const keywordLists = await getAllKeywordLists();
  return json({ keywordLists, debugMode });
};

function App() {
  const data = useLoaderData<typeof loader>();
  const {
    pronounPercent,
    pronounMaxPercent,
    clientPercent,
    clientMaxPercent,
    dialPercentClientKeywords,
    dialPercentAudienceKeywords,
    inputTextContext: { inputText, setInputText, questionCount },
  } = useInputAnalysis({ keywordLists: data.keywordLists });
  const [showHighlights, setShowHighlights] = useState(false);

  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setInputText(event.target.value);
  };

  const audienceCentricLevel = getLevelFromPercent(
    pronounPercent,
    pronounMaxPercent
  );

  const clientLevel = getLevelFromPercent(clientPercent, clientMaxPercent);
  const hasInput = !!inputText.match(/\S+(\W|\s)/g)?.length;

  // Initial call to confetti to insert the confetti canvas
  useEffect(() => {
    confetti("confetti", {
      count: 0,
    });
  }, []);

  const handleShowHighlights = () => {
    if (!showHighlights) {
      const dials = document.querySelectorAll(".line-dial");
      const leftRect = dials[0].getBoundingClientRect();
      const rightRect = dials[1].getBoundingClientRect();
      const confettiContainer = document.querySelector("#confetti canvas");
      if (!confettiContainer) return;
      const confettiRect = confettiContainer.getBoundingClientRect();

      if (audienceCentricLevel === 5) {
        confetti("confetti", {
          position: {
            x:
              ((leftRect.left + leftRect.width / 2) / confettiRect.width) * 100,
            y:
              ((leftRect.top + leftRect.height / 2) / confettiRect.height) *
              100,
          },
          spread: 1,
          startVelocity: 10,
        });
      }
      if (clientLevel === 5) {
        confetti("confetti", {
          position: {
            x:
              ((rightRect.left + rightRect.width / 2) / confettiRect.width) *
              100,
            y:
              ((rightRect.top + rightRect.height / 2) / confettiRect.height) *
              100,
          },
          spread: 1,
          startVelocity: 10,
        });
      }
    }

    setShowHighlights(!showHighlights);
  };

  return (
    <CenterColumn className="page language-eq">
      <img
        src="level-5-eq.png"
        className="sm:absolute top-0 sm:top-6 w-16 sm:w-20 md:w-28 mx-auto"
        alt="Level 5 EQ Logo"
      />
      <div className="flex flex-row items-baseline justify-center">
        <h1 className="font-bold text-lg sm:text-2xl">
          <YourLanguageEQLogo />{" "}
          <span className="text-being-ei-blue">Score</span>
        </h1>
      </div>
      <div>
        {data.debugMode && (
          <div>
            <h1>Debug</h1>
            <h2>Audience-Centric Pronouns</h2>
            <p>% of audience-centric pronouns: {pronounPercent}</p>
            <p>% that will fill dial: {pronounMaxPercent}</p>
            <p>% of Dial: {dialPercentAudienceKeywords}</p>
            <h2>Client-Centric Words</h2>
            <p>% of client-centric words: {clientPercent}</p>
            <p>% that will fill dial: {clientMaxPercent}</p>
            <p>% of Dial: {dialPercentClientKeywords}</p>
          </div>
        )}
        <div className="flex flex-row justify-center pb-4 text-center">
          <DialCollection
            hasInput={hasInput}
            audienceCentricLevel={audienceCentricLevel}
            audienceCentricPercent={dialPercentAudienceKeywords}
            clientLevel={clientLevel}
            clientPercent={dialPercentClientKeywords}
            questionCount={questionCount}
          />
        </div>
        {!showHighlights && (
          <div className="grow-wrap">
            <textarea
              placeholder="Type, Paste or Dictate your content here..."
              className="mb-4 mt-1 w-full rounded border border-gray-800 bg-white p-2 print:hidden"
              onChange={handleInputChange}
              value={inputText}
              required
            />
            <div className="whitespace-pre-line textarea hidden print:block">{inputText}</div>
          </div>
        )}
        {showHighlights && (
          <HighlightedOutput
            inputText={inputText}
            wordLists={data.keywordLists}
          />
        )}
        <ButtonRow className="mb-6">
          <Link to="/results-help" className="button-link tertiary mr-2 sm:mr-10">
            Help
          </Link>
          <button className="mr-2 bg-blue-500" onClick={handleShowHighlights}>
            {showHighlights ? "Back" : "Highlight"}
          </button>
          <button className="secondary mr-2" onClick={() => window.print()}>
            Print
          </button>
          <Link to="/quote" className="button-link primary">
            Get a Quote
          </Link>
        </ButtonRow>
      </div>
    </CenterColumn>
  );
}

export default App;
